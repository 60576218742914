import './../styles/main.scss';
import jquery from "jquery";

console.log("Hello, world 👋🏽");

window.$ = window.jQuery = jquery;

jQuery(function() {
  let menu = $("#site-menu"),
    topMenuHeight = menu.outerHeight(),
    menuItems = menu.find("a");

  // smooth scroll
  menuItems.on("click", function(e){
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 400);
    e.preventDefault();
  });

  // scrollspy
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 0) {
      // not at the top of the page
      $('#site-menu').addClass('nav-sticky');
      $('#top').addClass('pt-scroll');
    } else {
      // at the top of the page
      $('#site-menu').removeClass('nav-sticky');
      $('#top').removeClass('pt-scroll');
    }
  });

  $("#menuBtn").on('click', function(){
    $('#menuBtn').toggleClass('open');
    $('#menu').toggleClass('flex');
    $('#menu').toggleClass('hidden');
  })

  $('#toggleDark').on('change', function(){
    let darkMode = $('#toggleDark').is(":checked");
    if (darkMode) {
      $("html").addClass("dark");
    } else {
      $("html").removeClass("dark");
    }
  })
});
